import { Layout } from "@/components";
import Vue, { ComponentOptions, AsyncComponent } from "vue";
import { RedirectOption } from "vue-router";

type Component =
	| ComponentOptions<Vue>
	| typeof Vue
	| AsyncComponent
	| Promise<typeof import("*.vue")>;

interface IMeta {
	title?: string; // 设置该路由在侧边栏和面包屑中展示的名字 不设置时不展示
	icon?: string; // 设置该路由在左侧菜单展示的图标 element-ui的icon
	breadcrumbAll?: boolean; //(默认 true)如果设置为false，则整个面包屑不会显示 此时可以自己在页面中写自定义的面包屑
	breadcrumb?: boolean; // (默认 true)如果设置为false，该路由则不会在breadcrumb面包屑中显示
	breadcrumbTo?: boolean; // (默认 true)该路由对应的面包屑是否可以点击
	activeMenu?: string; // 指定要高亮的左侧边栏的菜单 在跳转菜单的子页面但是想高亮左侧边栏时非常有用
	auth?: string; // 权限点唯一标识key 不配置时默认都可见 配置时权限接口返回的数组中需包含该key才会展示
}

export interface IBaseRouter {
	path: string; // 路由
	name?: string; // 名称
	children?: IBaseRouter[]; // 子路由
	redirect?: RedirectOption; // 路由重定向
	meta?: IMeta;
	hidden?: boolean; // (默认 false)当设置 true 的时候该路由不会在侧边栏出现 如401，login等页面，或者如一些子页面编辑页面/edit/1
	component?: Component; // 路由渲染的组件页面
	props?: boolean; // 当 props 设置为 true 时，route.params 将被设置为组件的 props。
}

const routes: Array<IBaseRouter> = [
	{
		path: "/",
		redirect: "/home",
		hidden: true,
	},
	{
		path: "/login",
		name: "login",
		hidden: true,
		component: () => import("../views/Login.vue"),
	},
	{
		path: "/home",
		name: "home",
		component: Layout,
		redirect: "/home/index",
		meta: {
			title: "首页",
			icon: "el-icon-s-home",
		},
		children: [
			{
				path: "index",
				name: "index",
				hidden: true,
				component: () => import("../views/Home.vue"),
				meta: {
					title: "首页",
					breadcrumb: false,
				},
			},
			{
				path: "bar/:width/:height",
				name: "bar",
				props: true,
				hidden: true,
				component: () => import("@/components/echarts/Bar.vue"),
				meta: {
					title: "柱状图",
					activeMenu: "/home/index",
				},
			},
			{
				path: "pie/:width/:height",
				name: "pie",
				props: true,
				hidden: true,
				component: () => import("@/components/echarts/Pie.vue"),
				meta: {
					title: "饼图",
					activeMenu: "/home/index",
				},
			},
			{
				path: "line/:width/:height",
				name: "line",
				props: true,
				hidden: true,
				component: () => import("@/components/echarts/Line.vue"),
				meta: {
					title: "折线图",
					activeMenu: "/home/index",
				},
			},
		],
	},
	{
		path: "/icon",
		name: "icon",
		component: Layout,
		hidden: true,
		redirect: "/icon/iconfont",
		meta: {
			title: "图标",
			icon: "el-icon-s-flag",
		},
		children: [
			{
				path: "iconfont",
				name: "iconfont",
				component: () => import("../views/icon/IconFont.vue"),
				hidden: true,
				meta: {
					title: "阿里图库",
					breadcrumb: false,
				},
			},
		],
	}, {
		path: "/table",
		name: "table",
		component: Layout,
		redirect: "/table/basic",
		meta: {
			title: "商品管理",
			icon: "el-icon-s-grid",
		},
		children: [
			{
				path: "basic",
				name: "basic",
				component: () => import("../views/table/BasicTable.vue"),
				meta: {
					title: "商品列表",
				},
			},
			{
				path: "fenlei",
				name: "fenlei",
				component: () => import("../views/table/fenlei.vue"),
				meta: {
					title: "分类列表",
				},
			},
			{
				path: "Batchupload",
				name: "Batchupload",
				component: () => import("../views/table/Batchupload.vue"),
				hidden: true,
				meta: {
					title: "批量上传图片",
				},
			},
			{
				path: "tongbu",
				name: "tongbu",
				component: () => import("../views/table/tongbu.vue"),
				hidden: true,
				meta: {
					title: "批量同步商品",
				},
			},
			{
				path: "plstock",
				name: "plstock",
				component: () => import("../views/table/plstock.vue"),
				hidden: true,
				meta: {
					title: "批量修改库存",
				},
			},
			{
				path: "plprice",
				name: "plprice",
				component: () => import("../views/table/plprice.vue"),
				hidden: true,
				meta: {
					title: "批量更新价格",
				},
			},
			{
				path: "pladd",
				name: "pladd",
				component: () => import("../views/table/pladd.vue"),
				hidden: true,
				meta: {
					title: "批量新增",
				},
			},
			{
				path: "plkeyword",
				name: "plkeyword",
				component: () => import("../views/table/plkeyword.vue"),
				hidden: true,
				meta: {
					title: "批量关键词",
				},
			},
			{
				path: "plkaidian",
				name: "plkaidian",
				component: () => import("../views/table/plkaidian.vue"),
				hidden: true,
				meta: {
					title: "一键搬店",
				},
			},
		],
	},
	{
		path: "/home",
		name: "shop",
		component: Layout,
		redirect: "/home/shop",
		meta: {
			title: "平台管理",
			icon: "iconfont icon-admin-dianpu u-mtt",
		},
		children: [
			{
				path: "shop",
				name: "shop",
				component: () => import("../views/Shop.vue"),
				hidden: true,
				meta: {
					title: "平台管理",
					breadcrumb: false,
				},
			},
		],
	},	{
		path: "/home",
		name: "comment",
		component: Layout,
		redirect: "/home/comment",
		meta: {
			title: "顾客评价",
			icon: "el-icon-s-comment",
		},
		children: [
			{
				path: "comment",
				name: "comment",
				component: () => import("../views/Comment.vue"),
				hidden: true,
				meta: {
					title: "顾客评价",
				},
			},
		],
	},
	{
		path: "/home",
		name: "kefu",
		component: Layout,
		redirect: "/home/kefu",
		meta: {
			title: "客服消息",
			icon: "iconfont icon-admin-kefu u-mtt",
		},
		children: [
			{
				path: "kefu",
				name: "kefu",
				component: () => import("../views/Kefu.vue"),
				hidden: true,
				meta: {
					title: "客服消息",
					breadcrumb: false,
				},
			},
		],
	},
	{
		path: "/form",
		name: "form",
		component: Layout,
		redirect: "/form/basicForm",
		hidden: true,
		meta: {
			title: "表单",
			icon: "el-icon-tickets",
		},
		children: [
			{
				path: "basicForm",
				name: "basicForm",
				component: () => import("../views/form/BasicForm.vue"),
				meta: {
					title: "添加/修改",
				},
			},
		],
	},
		{
		path: "/component",
		name: "component",
		component: Layout,
		hidden: true,
		redirect: "/component/editor",
		meta: {
			title: "组件",
			icon: "el-icon-s-opportunity",
		},
		children: [
			{
				path: "editor",
				name: "editor",
				component: () => import("../views/component/EditorPage.vue"),
				meta: {
					title: "富文本",
				},
			},
		],
	},
	{
		path: "*",
		redirect: "/error/404",
		hidden: true,
	},
];

export default routes;
