
import { Vue, Component, Prop } from "vue-property-decorator";
import { IBaseRouter } from "@/router/config";
import path from "path";
@Component({
  name: "SubMenu",
})
export default class SubMenu extends Vue {
  @Prop() menu!: any;
  @Prop({ default: "" }) basePath!: string;

  get icon() {
    return this.menu?.meta?.icon;
  }

  private getMenuTitle(menu: IBaseRouter) {
    return menu?.meta?.title;
  }

  private resolvePath() {
    // 如果该路由有重定向配置&子路由都是隐藏了的 就会默认将重定向的路由作为菜单项index显示在侧边栏中
    if (
      this.menu.redirect &&
      !this.menu.children.some((v: IBaseRouter) => !v.hidden)
    )
      return this.menu.redirect;
    else return path.resolve(this.basePath, this.menu.path);
  }
}
